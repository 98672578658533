body {
  width: 100%;
}

#App {
  overflow-x: hidden;
  width: 100%;
  max-width: 1500px;
  font-family: 'Inter', sans-serif;
}

#root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/******** special calls ******/
.perfectCentering {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flexCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexColumnStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flexSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexSpaceBetweenFirstBaseeline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline;
}

.borderlessTransparentBtn {
  background-color: transparent;
  border: 0px;
}

@keyframes loadingBgAnimation {
  from {
    background: linear-gradient(30deg, rgb(237, 237, 237), white, white, white, white, white, white, white,
        white);
  }

  20% {
    background: linear-gradient(30deg, white, rgb(237, 237, 237), white, white, white, white, white, white,
        white);
  }

  30% {
    background: linear-gradient(30deg, white, white, rgb(237, 237, 237), white, white, white, white, white,
        white);
  }

  40% {
    background: linear-gradient(30deg, white, white, white, rgb(237, 237, 237), white, white, white, white,
        white);
  }

  50% {
    background: linear-gradient(30deg, white, white, white, white, rgb(237, 237, 237), white, white, white,
        white);
  }

  60% {
    background: linear-gradient(30deg, white, white, white, white, white, rgb(237, 237, 237), white, white,
        white);
  }

  70% {
    background: linear-gradient(30deg, white, white, white, white, white, white, rgb(237, 237, 237), white,
        white);
  }

  80% {
    background: linear-gradient(30deg, white, white, white, white, white, white, white,
        rgb(237, 237, 237),
        white, );
  }

  90% {
    background: linear-gradient(30deg, white, white, white, white, white, white, white,
        white, rgb(237, 237, 237));
  }

  100% {
    background: linear-gradient(30deg, white, white, white, white, white, white, white,
        white, white);
  }
}

@keyframes translateFromLeft {
  from {
    opacity: 0;
    transform: translate(-50%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }

}

@keyframes translateFromRight {
  from {
    opacity: 0;
    transform: translate(50%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }

}

@keyframes translateFromBottom {
  from {
    opacity: 0;
    transform: translate(0, 50%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }

}

@keyframes translateFromTop {
  from {
    opacity: 0;
    transform: translate(0, -50%);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }

}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
  }

  to {
    transform: scale(1, 1);
  }

}

/******************** re ***********************/
/*************************** ge  **************/

#ge1 {
  margin: 140px 6vw 10px 6vw;
}

.ge2 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  color: #000485;
  text-align: left;
}

.ge3 {
  max-height: 3rem;
  margin-right: 50px;
}

.ge4 {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #2C2B2B;
  margin-bottom: 3%;
}

.ge5 {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Space Grotesk', sans-serif;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #2C2B2B;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: none;
}

#ge6 {
  cursor: pointer;
  height: 80px;
  width: 100vw;
  position: fixed;
  opacity: .2;
  background-color: black;
  animation-name: ge6;
  animation-duration: .4s;
  animation-iteration-count: 1;
  animation-direction: normal;
  top: 0px;
  left: 0px;
  z-index: 11;
}

@keyframes ge6 {
  from {
    opacity: 0;
  }

  to {
    opacity: .2;
  }
}

#ge7 {
  cursor: pointer;
  height: 100vh;
  width: 100vw;
  position: fixed;
  opacity: .2;
  background-color: black;
  animation-name: ge6;
  animation-duration: .4s;
  animation-iteration-count: 1;
  animation-direction: normal;
  top: 0px;
  right: 0px;
  z-index: 2;
}

#ge8 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  min-height: 280px;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2%;
  box-shadow: 0px 8px 20px rgb(85, 85, 85);
  z-index: 3;

}

#ge9 {
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: 600;

}

#ge10 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  opacity: .9;
}

#ge11 {
  background-color: #000485;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  padding: 7px 15px 7px 15px;
  border-radius: 5px;
  border: 0px;
  margin-top: 20px;
}

#ge12 {
  color: green;
  font-size: 25px;

}

#ge13 {
  color: red;
  font-size: 25px;
}

#ge14 {
  text-decoration: none;
}

#ge15 {
  margin: 105px 6vw 10px 6vw;
}

/********************** hmre ****************************/
#hmre1 {
  margin-top: 50px;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 100px;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#hmre2 {
  list-style: none;
  font-Size: 12px;
  font-Family: 'Inter', sans-serif
}

#hmre3 {
  color: #3B4052;
}

#hmre4 {
  color: #000485;
  font-weight: 700;
  text-decoration: underline;
  border: 0px;
  background-color: transparent;
  max-width: 100%;

}

#hmre5 {
  max-height: 500px;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: translateFromRight;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
  z-index: 1;
}



#hmre6 {
  max-width: 100%;
  overflow: hidden;
  animation-name: loadingBgAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#hmre7 {
  padding-top: 30px;
}

#hmre8 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  letter-spacing: 1%;
  color: rgba(116, 116, 116, 1);
}





#hmre12 {
  height: 155px;
  width: 100%;
  background-color: white;
  position: relative;
  top: -50px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(219, 226, 230);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
  overflow: hidden;
}



#hmre14 {
  width: 100%;
  background-color: #F5F5F5;
  margin-top: 100px;
  padding: 2vw 1vw 2vw 1vw;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#hmre15 {
  margin-bottom: 30px;
  width: 100%;
  text-align: start;
}

#hmre16 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  overflow: scroll;
}

#hmre16::-webkit-scrollbar {
  display: none;

}

#hmre17 {
  background-color: white;
  height: 530px;
  width: 96%;
  max-width: 352px;
  flex-shrink: 0;
  margin-right: 30px;
  box-shadow: 0px 5px 5px rgb(238, 238, 238);
  margin-bottom: 5px;
  overflow: hidden;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;

}

#hmre18 {
  height: 258px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

}

#hmre19 {
  max-width: 125%;
  animation-name: loadingBgAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#hmre20 {
  padding: 5% 7% 0% 7%;
}

#hmre21 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000485;
}

#hmre22 {
  font-Family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(93, 93, 93, 1);
}

#hmre23 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  width: 100%;
  background-color: #000485;
  color: white;
  border: 0px;
  padding: 15px 10px 15px 10px;
  margin-top: 20px;
}



#hmre24 {
  margin-left: 10px;
}

#hmre25 {
  height: 165px;
}

#hmre26 {
  background-color: white;
  height: 520px;
  width: 96%;
  max-width: 352px;
  flex-shrink: 0;
  margin-right: 30px;
  box-shadow: 0px 5px 5px rgb(238, 238, 238);
  margin-bottom: 5px;
  overflow: hidden;

}

#hmre28 {
  display: none;
  animation-name: translateFromRight;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;

}

#hmre29 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: marquee;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-duration: 40s;
  animation-delay: 2s;
  padding-left: 150px;
  -o-animation-timing-function: ease-in-out;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);

  }

  20% {
    transform: translate(-200px, 0);

  }

  40% {
    transform: translate(-400px, 0);

  }

  60% {
    transform: translate(-600px, 0);

  }

  80% {
    transform: translate(-800px, 0);

  }

  90% {
    transform: translate(-1000px, 0);

  }


}

#hmre30 {
  width: 100%;
}

#hmre31 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

#hmre32 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #F5F5F5;
  padding: 20px 10px 20px 10px;
  margin-top: 100px;
}

#hmre33 {
  margin: 0px 0px 100px 0px;
}

#hmre34 {
  width: 100%;
  max-width: 1498px;
}

#hmre35 {
  width: 90%;
  height: 380px;
  max-width: 500px;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

#hmre36 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}

#hmre37 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

}

#hmre38 {
  width: 112px;
  height: 111px;
  background-color: white;
  padding: 10px 10px 20px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#hmre39 {
  background-color: #FFC700;
  width: 21px;
  height: 21px;
  list-style: none;
  border-radius: 50%;
  flex-shrink: 0;
}

#hmre40 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -21px;

}

#hmre41 {
  list-style: none;
  color: #3B4052;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}

#hmre42 {
  list-style: none;
  color: #3B4052;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

#hmre43 {
  background-color: #FE006F;
  width: 21px;
  height: 21px;
  list-style: none;
  border-radius: 50%;
  flex-shrink: 0;
}

.hmre45b:hover .hmre45 {
  margin-right: 10px;
}

.hmre46b:hover .hmre46 {
  margin-right: 10px;
}

.hmre47b:hover .hmre47 {
  margin-right: 10px;
}

.hmre48b:hover .hmre48 {
  margin-right: 10px;
}

.hmre49b:hover .hmre49 {
  margin-right: 10px;
}

/************** ucre ***********/
#ucre1 {
  width: 100%;
  background-color: white;
  margin-top: 30px;
  padding-left: 5vw;
  padding-right: 5vw;
  background-image: url("./images/Pattern\ -\ outline.png");
  background-repeat: no-repeat;
  background-size: 130px;
  background-position: left 0px top -150px;
}

#ucre18 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#ucre2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

#ucre3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

#ucre4 {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;

}

#ucre5 {
  font-Family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(59, 64, 82, 1);
}

#ucre6 {
  border: 1px solid #000485;
  background-color: transparent;
  color: #000485;
  width: 150px;
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-top: 20px;

}

#ucre7 {
  background-color: white;
  display: none;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#ucre8 {
  width: 100%;
  background-color: #B4ADB4;
  border: 10px solid #B4ADB4;
  max-width: 610px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0px 40px 0px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  animation-name: translateFromRight;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#ucre8::-webkit-scrollbar {
  display: none;
}

#ucre9 {
  width: 90%;
  height: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 2px 4px rgb(137, 137, 137);
  flex-shrink: 0;
  margin: 0% 5% 0% 5%;
}

#ucre10 {
  max-width: 110%;
}

#ucre11 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'Space Grotesk', sans-serif;
}

#ucre12 {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4052;
  font-family: 'Inter', sans-serif;
}

#ucre13 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

#ucre14 {
  width: 18px;
  height: 18px;
  background: #0052FF;
  border: 0px;
  border-radius: 100%;
  margin-right: 10px;
}

#ucre15 {
  width: 18px;
  height: 18px;
  background: #D9D9D9;
  border: 0px;
  border-radius: 50%;
  margin-right: 10px;
}

#ucre16 {
  border: 0px;
  background-color: transparent;
  margin-left: 5px;

}

#ucre17 {
  width: 100%;
  display: none;
}

#ucre19 {
  width: 90%;
  height: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 2px 4px rgb(137, 137, 137);
  flex-shrink: 0;
  margin: 0% 5% 0% 5%;
}


#ucre20 {
  height: 40px;
  width: 40px;
  color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ucre21b:hover .ucre21 {
  margin-right: 10px;
}





/**************** co *******************/
.co1 {
  background-color: palevioletred;
}

.co2 {
  background-color: burlywood;
}

.co3 {
  background-color: cadetblue;
}

.co4 {
  background-color: darkslategray;
}

/************************ msre **************************/
#msre1 {
  background-color: rgba(125, 129, 250, 0.09);
  height: 551px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("./images/Pattern\ -\ outline2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: soft-light;
  margin-top: 50px;
}

#msre2 {
  background-color: white;
  width: 95vw;
  max-width: 717px;
  height: 330px;
  top: 141px;
  left: 362px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("./images/Pattern\ -\ outline2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: darken;
  box-shadow: 0px 2px 5px rgb(225, 225, 225);

}

#msre3 {
  font-size: 48px;
  font-weight: 700;
  font-family: 'Space Grotesk', sans-serif;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  color: #000485;
}

#msre4 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
}

#msre5 {
  max-width: 80%;
}

#msre6 {
  width: 100%;
  border: 1px solid #000485;
  height: 47px;
  padding: 10px;
  font-Family: 'Inter', sans-serif;
  outline-width: 2px;
  outline-color: #000485;

}


#msre6::placeholder {
  font-Family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000485;
}

#msre7 {
  height: 47px;
  background-color: #000485;
  color: white;
  border: 0px;
  width: 100%;
  font-size: 15px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #000485;
}

#msre8 {
  margin-top: 10px;
  width: 70%;
  min-width: 260px;
}

#msre9 {
  margin-top: 10px;
  text-align: center;
  height: 47px;
  width: 30%;
  flex-shrink: 0;
}

#msre10 {
  width: 100%;
  border: 1px solid red;
  border-right: 0px;
  height: 47px;
  padding: 10px;
  font-Family: 'Inter', sans-serif;
  outline-width: 2px;
  outline-color: red;

}


#msre11 {
  width: 100%;
  text-align: left;
  color: red;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

#msre12 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

#msre10::placeholder {
  font-Family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;

}

/********************************** ftre ******************************/
#ftre1 {
  background-color: #000485;
  min-height: 342px;
  width: 100%;
  padding: 20px 6vw 20px 6vw;
  color: white;
}

#ftre2 {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline baseline;
  flex-wrap: wrap;

}

#ftre3 {
  max-width: 300px;
  padding: 0px;
  margin-right: 40px;
}

#ftre4 {
  max-height: 44px;

}

#ftre5 {
  font-size: 25px;
  font-weight: 1000;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  list-style: none;
  margin-bottom: 20px;
}

#ftre6 {
  font-family: 'Inter', sans-serif;
  list-style: none;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  border: 0px;
  background-color: transparent;
}

#ftre7 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px 10px 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 0px;
  background-color: transparent;
}


#ftre8 {
  color: white;
  font-size: 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
}

#ftre9 {
  list-style: none;
  margin-right: 10px;
  border: 0px;
  background-color: transparent;
  padding: 0px;
}

#ftre10 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;

}

#ftre11 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

#ftre12 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  margin-right: 10px;
  border: 0px;
  background-color: transparent;
  color: white;
}

#ftre13 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
}

#ftre14 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline;
  flex-wrap: wrap;

}

#ftre15 {
  margin-right: 5px;
}

#ftre16 {
  border: 0px;
  background-color: transparent;
  color: white;
  padding: 0px;
  padding: 0px;
}

#ftre16:hover {
  color: rgb(208, 208, 208);
}

#ftre17 {
  border: 0px;
  background-color: transparent;
  border: 0px;
  color: white;
  padding: 0px;
  cursor: auto;
}

#ftre18 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  margin-right: 10px;
  border: 0px;
  background-color: transparent;
  color: white;
  cursor: auto;
}

#ftre22 {
  display: none;
}

#ftre20 {
  margin-bottom: 30px;

}

#ftre21 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;

}

#ftre22 {
  background-color: #000485;
  min-height: 342px;
  width: 100%;
  padding: 20px 6vw 20px 6vw;
  color: white;
}

#ftre23 {
  font-size: 15px;
  text-align: left;
  padding: 0px;


}

#ftre24 {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  margin-top: 5px;
  margin-bottom: 13px;
}

/************************ abre ***************************/
#abre1 {
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;

}

#abre2 {
  font-Family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #747474;
  margin-bottom: 50px;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#abre3 {
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#abre4 {
  max-width: 100%;
  animation-name: loadingBgAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#abre5 {
  height: 155px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 20px rgb(219, 226, 230);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
  overflow: hidden;
}


#abre6 {
  background-image: url("./images/Pattern\ -\ outline.png");
  background-repeat: no-repeat;
  background-size: 130px;
  background-position: left 0px top -150px;
  min-height: 505px;
  min-width: 100%;
  padding: 0px 6vw 10px 6vw;
  margin-top: 50px;
}

#abre7 {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}


#abre8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#abre9 {
  margin-bottom: 10px;
}

#abre10 {
  font-Family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #747474;
}

#abre11 {
  background-color: white;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
  display: none;
}

#abre12 {
  width: 189px;
  margin-right: 5px;
  position: relative;
  top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

#abre13 {
  width: 189px;
  margin-left: 5px;

  position: relative;
  bottom: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

}

#abre14 {
  width: 100%;
  height: 181px;
  margin-bottom: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromTop;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  display: none;
}

#abre15 {
  width: 100%;
  height: 248px;
  margin-top: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  display: none;

}

#abre16 {
  width: 100%;
  height: 248px;

  margin-bottom: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromRight;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  display: none;
}

#abre17 {
  width: 100%;
  height: 181px;
  margin-top: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  display: none;
}

#abre18 {
  max-width: 125%;
  animation-name: loadingBgAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#abre19 {
  margin-top: 100px;
}

#abre20 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

#abre21 {
  height: 450px;
  width: 100%;
  max-width: 360px;
  flex-shrink: 0;
  margin: 5px;
  overflow: hidden;
}


#abre22 {
  height: 360px;
  width: 100%;
  background-color: rgb(236, 236, 236);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#abre23 {
  max-width: 107%;
  animation-name: loadingBgAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
transform: scale(1, 1)
}

#abre22:hover #abre23 {
    animation-name: abre22;
    animation-duration: 1s;
    animation-iteration-count: 1;
        transform: scale(1.05, 1.05);
}

@keyframes abre22 {
  from {
    transform: scale(1, 1)
  }

  to {
    transform: scale(1.05, 1.05);
  }
}

#abre24 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000485;
}

#abre25 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 0px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #747474;

}

#abre26 {
  padding: 5px 10px 0px 0px;
}

#abre27 {
  line-height: 0px;
}

#abre28 {
  line-height: 0px;
  margin-right: 10px;
  font-size: 20px;
  border: 0px;
  background-color: transparent;
  padding: 0px;

}

#abre28:hover {
  color: #000485
}

#abre29 {
  margin-bottom: 100px;
}

#abre30 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  color: #000485;
  text-align: left;

}

#abre31 {
  height: 155px;
  width: 100%;
  background-color: rgb(255, 255, 255, .9);
  box-shadow: 0px 0px 20px rgb(219, 226, 230);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
  overflow: hidden;
}

x
/****************************** sere ****************************/


#sere2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;

}

#sere1 {
  color: #747474;

  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

#sere3 {
  flex-shrink: 0;
  background-color: #B4ADB4;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  border: 0px;
  color: white;
  padding: 10px;
  margin: 10px 10px 10px 0px;
  cursor: auto;
}

#sere4 {
  background-color: #000485;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 17px;
  font-weight: 600;
  border: 0px;
  width: 150px;
  padding: 15px 0px 15px 0px;
  margin-right: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;

}

#sere4:hover .sere19 {
  margin-right: 10px;
}

#sere5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#sere6 {
  width: 189px;
  margin-right: 5px;
  position: relative;
  top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

#sere7 {
  width: 189px;
  margin-left: 5px;
  position: relative;
  bottom: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

}

#sere8 {
  width: 100%;
  height: 181px;
  margin-bottom: 7px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromTop;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  background-color: #00BBFF;
  display: none;
}

#sere9 {
  width: 100%;
  height: 248px;
  margin-top: 7px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  display: none;
}

#sere10 {
  width: 100%;
  height: 248px;
  margin-bottom: 7px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromRight;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  display: none;

}

#sere11 {
  width: 100%;
  height: 181px;
  margin-top: 7px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  background-color: #FE006F;
  display: none;
}

#sere12 {
  margin-bottom: 50px;
}

#sere13 {
  margin-bottom: 50px;
}

#sere15 {
  max-width: 130%;
  animation-name: loadingBgAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#sere16 {
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;

}

#sere17 {
  width: 189px;
  margin-left: 5px;
  position: relative;
  top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

#sere18 {
  width: 189px;
  margin-right: 5px;
  position: relative;
  bottom: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

}


/*************************** pfre **************************/
#pfre1 {
  width: 100%;
  background-color: #000485;
  margin-top: 120px;
  padding: 80px 6vw 200px 6vw;

}


#pfre2 {
  padding: 0px
}

#pfre3 {}

#pfre4 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

#pfre5 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #D6D6D6;
}

#pfre6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

#pfre7 {
  flex-shrink: 0;
  text-align: center;
  font-family: ' Space Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  padding: 10px 0px 10px 0px;
  height: 60px;
}

#pfre8 {
  text-align: start;
  color: #FE006F;
  border: 0px;
  border-left: 2px solid #FE006F;
  padding: 10px 0px 10px 10px;
  list-style: none;
  background-color: transparent;
  animation-name: pfre8;
  animation-duration: .5s;
  animation-iteration-count: 1;
}

@keyframes pfre8 {
  from {
    transform: translate(0, -10px);
  }

  to {
    transform: translate(0, 0);
  }
}

#pfre9 {
  list-style: none;
  background-color: transparent;
  border: 0px;
  color: white;
}

#pfre10 {
  width: 88vw;
  max-width: 1200px;
}

#pfre11 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  top: -100px;
  height: 450px;
}

#pfre12 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  border: 3px solid white;
  overflow: hidden;
  height: 500px;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px 10px 4% 10px;
  cursor: pointer;
  animation-name: translateFromBottom;
  animation-duration: 1s;
  animation-iteration-count: 1;

}



#pfre12:hover {
  background-color: #8588d3;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
}

#pfre12:hover div {
  display: flex;
}

#pfre13 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 500px;
}

#pfre14 {
  height: 500px;
  width: 120%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


}

#pfre15 {
  max-height: 120%;
}

#pfre16 {
  height: 100%;
  width: 50%;
}

#pfre17 {
  height: 100%;
  width: 50%;
}

#pfre18,
#pfre19,
#pfre20,
#pfre21 {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  border: 3px solid white;
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px 10px 4% 10px;
  cursor: pointer;
}

#pfre18:hover,
#pfre19:hover,
#pfre20:hover,
#pfre21:hover {
  background-color: #8588d3;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
}


#pfre18:hover div,
#pfre19:hover div,
#pfre20:hover div,
#pfre21:hover div {
  display: flex;
}

#pfre18 {
  animation-name: translateFromLeft;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

#pfre19 {
  animation-name: translateFromRight;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

#pfre20 {
  animation-name: translateFromBottom;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

#pfre21 {
  animation-name: translateFromBottom;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

#pfre22 {
  list-style: none;
  width: 100%;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#pfre23 {
  background-color: #000485;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

#pfre24 {
  line-height: 20px;
  text-shadow: 0px 0px 10px black;
}

#pfre25 {
  margin: 0px 6vw 10px 6vw;
}

#pfre26 {
  margin-bottom: 20px;
}

#pfre27 {
  width: 100%;
  overflow: hidden;
  margin-top: 100px;
}

#pfre28,
#pfre29 {
  padding: 0px;
  padding: 0px;
}


#pfre30 {
  max-width: 100%;
}

#pfre31 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #747474;
}

#pfre32 {
  width: 112px;
  height: 111px;
  border-radius: 8px;
  flex-shrink: 0;
  margin: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#pfre33 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#pfre34 {
  width: 21px;
  height: 21px;
  left: 45px;
  background-color: #FFC700;
  border-radius: 50%;
}

#pfre35 {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #3B4052;
}

#pfre36 {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

#pfre37 {
  width: 21px;
  height: 21px;
  left: 45px;
  background-color: #FE006F;
  border-radius: 50%;
}

#pfre38 {
  color: #000485;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 50px;
  margin-top: 50px;
}

#pfre39 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 2000px;
  overflow-x: scroll;

}

#pfre40 {
  width: 310px;
  padding: 24px 8px 24px 8px;
  border-radius: 8px;
  gap: 12px;
  flex-shrink: 0;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: -2px 7px 59px rgba(21, 23, 72, 0.05);
  height: 480px;
}

#pfre42 {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0px;
}

#pfre43 {
  max-width: 100%;
  flex-shrink: 0px;
}

#pfre44 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #000485;
  list-style: none;
}

#pfre45 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #747474;
  list-style: none;
  margin-bottom: 15px;

}

#pfre46 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  color:
    #747474;
  height: 180px;
  width: 100%;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;

}

#pfre47 {
  color: #0A6FE7;
  border: 0px;
  background-color: transparent;
  padding: 0px;
  font-size: 24px;
  margin: 5px;

}

#pfre48 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 3px solid white;
  overflow: hidden;
  max-height: 500px;
  background-color: #F5F5F5;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  animation-name: scaleOut;
  animation-duration: 1s;
  animation-iteration-count: 1;

}


#pfre49 {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

#pfre50 {
  position: relative;
  top: 0%;
  z-index: 9;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



#pfre52 {
  color: white;
  font-size: 2rem;

}

#pfre56 {
  width: 100%;
  max-height: 100%;
}

/****************************** cure ***********************************/

#cure1 {}

#cure2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#cure3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


#cure4 {
  width: 100%;
  max-height: 374px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  animation-name: translateFromRight;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#cure5 {
  width: 30%;
  max-width: 150px;
}

#cure6 {
  max-width: 428px;
  animation-name: translateFromBottom;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}



#cure7 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4052;
}

#cure8 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4052;
  list-style: none;
  margin: 0px;
  padding: 0px;

}

#cure9 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4052;
  list-style: none;
  margin-bottom: 15px;
}

#cure10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

#cure11 {
  list-style: none;
  margin-right: 10px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4052;
}

#cure12 {
  list-style: none;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4052;
}


#cure13 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B4052;
  list-style: none;
  margin-right: 5px;
}

#cure14 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-bottom: 30px;
}

#cure15 {
  margin-bottom: 15px;
}

#cure16 {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
  list-style: none;
  margin-bottom: 5px;
}

#cure17 {
  background-color: transparent;
  border: 1px solid white;
  width: 100%;
  height: 51px;
  outline-color: white;
  color: white;
  padding: 10px;

}


#cure18 {
  background-color: transparent;
  border: 1px solid white;
  width: 100%;
  height: 150px;
  outline-color: white;
  color: white;
  padding: 10px;
  resize: none;
}

#cure19 {
  width: 185px;
  height: 43px;
  background-color: white;
  color: #000485;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: center;
  border: 0px;
}

#cure20 {
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#cure21 {
  background-color: #000485;
  padding: 20px 4vw 20px 4vw;
  width: 100%;
  max-width: 610px;
  flex-shrink: 0;

}

#cure23 {
  background-color: transparent;
  border: 1px solid red;
  width: 100%;
  height: 51px;
  color: white;
  padding: 10px;

}

#cure23:focus {
  outline: 2px solid red;
}

#cure24 {
  width: 100%;
  text-align: left;
  color: red;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

#cure25 {
  background-color: transparent;
  border: 1px solid red;
  width: 100%;
  height: 150px;
  outline-color: red;
  color: white;
  padding: 10px;
  resize: none;
}

#cure25:hover {
  outline: 2px solid red;
}

#cure26 {
  color: #000485;
}


/******** blurred background **********/



#transperentBackgroundOverlay {
  cursor: pointer;
  height: 100vh;
  width: 100%;
  max-width: 1500px;
  position: fixed;
  opacity: .1;
  background-color: black;
  top: 80px;
  animation-name: BluredBackgroundOverlay;
  animation-duration: .4s;
  animation-iteration-count: 1;
  animation-direction: normal;
  z-index: 2;
}




/********** landing page NavBar **************/
#mainNavBar {
  background-color: white;
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 6vw 10px 6vw;
  font-family: 'Space Grotesk', sans-serif;
  height: 80px;
  max-width: 1500px;
  position: fixed;
  top: 0px;
  z-index: 5;

}

#mainNavCompanyLogo {
  height: 52px;
  cursor: pointer;

}

.mainNavRoutes {
  border: 0px;
  background-color: transparent;
  margin-right: 1.6vw;
  margin-left: 1.6vw;
  font-size: 18px;
  font-weight: 400;
  color: #9399A3;
}

.mainNavRoutes:hover {
  color: #3B4052;
}

.activeMainNavRoutes {
  border: 0px;
  background-color: transparent;
  list-style: none;
  margin-right: 1.6vw;
  margin-left: 1.6vw;
  font-size: 18px;
  font-weight: 700;
  color: #3B4052;
  border-bottom: 1px solid #006AFF;
}

#mainNavContactUsBtn {
  padding: 9px 15px 9px 15px;
  width: 180px;
  height: 52px;
  border: 0px;
  border: 2px solid #000485;
  background-color: #000485;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

#mainNavContactUsBtn:hover {
  background-color: white;
  color: #000485;
  animation-name: mainNavContactUsBtn;
  animation-duration: .5s;
  animation-iteration-count: 1;

}

@keyframes mainNavContactUsBtn {
  from {
    background: #000485;
    color: white;
  }

  to {
    background-color: white;
    color: #000485;
  }

}

#mainNavDropContactUsBtn {
  padding: 9px 15px 9px 15px;
  width: 210px;
  height: 52px;
  border: 2px solid #000485;
  background-color: #000485;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  margin: 15px 10px 15px 6vw;
}

#mainNavDropContactUsBtn:hover {
  background-color: white;
  color: #000485;
}

#mainNavTogglerBtn {
  border: 0px;
  background-color: transparent;
  font-size: 30px;
  margin-left: 20px;
  color: #000485;
  text-shadow: 0px 0px 10px black;
  display: none;
  padding: 0px;
}

#mainNavDropMenu {
  width: 100%;
  background-color: white;
  position: absolute;
  top: 75px;
  right: 0vw;
  border-bottom-left-radius: 30px;
  box-shadow: 0px 5px 5px rgb(174, 174, 174);
  animation-name: mainNavDropMenu;
  animation-duration: .5s;
  animation-iteration-count: 1;
  display: none;
  overflow: hidden;

}

@keyframes mainNavDropMenu {
  from {
    top: 50px;

  }

  to {
    top: 75px;
  }
}

#NavServicesDropMenu {
  background-color: white;
  box-shadow: 0px 3px 5px rgb(175, 175, 175);
  position: absolute;
  top: 75px;
  left: 50%;
}

#navDNavServiceDropdown {
  animation-name: navDNavServiceDropdown;
  animation-duration: 1s;
  animation-direction: normal;
  background-color: white;

}

@keyframes navDNavServiceDropdown {
  from {

    transform: translate(0, -20px);
  }

  to {

    transform: translate(0);
  }
}

#sdl {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  list-style: none;
}

#sdl4 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
}

#sdl2 {
  font-family: 'Space Grotesk', sans-serif;
  padding: 5px 14px 5px 14px;
  border: 0px;
  background-color: transparent;
  width: 100%;
  color: #9399A3;
}

#sdl3 {
  font-family: 'Space Grotesk', sans-serif;
  padding: 5px 14px 5px 14px;
  border: 0px;
  background-color: transparent;
  width: 100%;
  color: #000485;
}

#sdl2:hover,
#sdl3:hover,
#sdl6:hover,
#sdl5:hover {
  color: #000485;
}

#sdl5 {
  font-family: 'Space Grotesk', sans-serif;
  padding: 15px 6vw 15px 6vw;
  border: 0px;
  background-color: transparent;
  width: 100%;
  color: #9399A3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;

}

#sdl6 {
  font-family: 'Space Grotesk', sans-serif;
  padding: 15px 6vw 15px 6vw;
  border: 0px;
  background-color: transparent;
  width: 100%;
  color: #000485;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
}


/****************** care ************/
#care1 {
  font-Family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #747474;
  margin-bottom: 50px;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}

#care2 {
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#care3 {
  background-color: #000485;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  font-weight: 600;
  border: 0px;
  padding: 15px 30px 15px 30px;
  margin-bottom: 20px;
}

#care4 {
  width: 100%;
  max-width: 600px;
}

#care5 {
  color: #000485;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 50px;
}

#care6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  flex-shrink: 0;
  max-width: 1500px;
}

#care7 {
  width: 100%;
  max-width: 375px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  height: 300px;

}




#care8 {
  width: 100%;

}

#care9 {
  width: 100%;
  height: 100%;
}

#care10 {
  font-size: 60px;
  height: 90px;
}

#care11 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: start;
  color: black;
}

#care12 {
  font-Family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #747474;
  margin-bottom: 50px;


}

#care13 {
  background-color: white;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 2%;
  margin-top: 20px;
}

#care14 {
  font-Family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #747474;
}

#care15 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0px 10px 20px #b7b8d8;
}

#care16 {
  max-width: 100%;
}

/************* p and pc **************/
@keyframes pc {
  from {
    opacity: 0;
    transform: translate(0, 30%) scale(.7, .7);

  }

  to {
    opacity: 1;
    transform: translate(0) scale(1, 1);

  }

}

.pc1,
.pc2,
.pc3,
.pc4,
.pc5,
.pc6 {
  display: none;
  animation-name: pc;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: .8s;
}








#eachMainNavDropMenuLinks {
  border: 0px;
  width: 100%;
  background-color: transparent;
  list-style: none;
  font-size: 1.1rem;
  font-family: 'Space Grotesk', sans-serif;
  padding: 15px 6vw 15px 6vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #9399A3;

}

#activeMainNavDropMenuLinks {
  border: 0px;
  width: 100%;
  background-color: transparent;
  font-size: 1.2rem;
  font-family: 'Space Grotesk', sans-serif;
  padding: 15px 6vw 10px 6vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #000485;
  font-weight: 600;

}

#eachMainNavDropMenuLinks:hover,
#activeMainNavDropMenuLinks:hover {
  background-color: rgb(239, 239, 239);
}


/*********************** homepage ********************/

#homepage {
  width: 100%;
  margin-top: 120px;
  font-family: 'Space Grotesk', sans-serif;
}

#onboarding {
  margin: 10px 6vw 10px 6vw;
}

#onboardingBrief {
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#onboardingContent {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1%;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  list-style: none;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
  color: #747474
}

#boldOnboardingText {
  font-size: 3rem;
  color: #000485;
  font-weight: 700;
  list-style: none;
  font-family: 'Space Grotesk', sans-serif;
  min-height: 120px;
  padding: 0px;
  line-height: 81.66px;
  animation-name: translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;
}


#onboardingGetstartedBtn {
  background-color: #000485;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 17px;
  font-weight: 600;
  border: 0px;
  width: 180px;
  padding: 15px 0px 15px 0px;
  margin-right: 30px;
  margin-bottom: 20px;
}

#onboardingGetstartedBtn:hover #hmre44 {
  margin-right: 10px;
}

.trainedTechieProfileImg {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  position: relative;
}


#visionImgContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 250px;
  max-width: 400px;
}

#visionImg {
  width: 100%;
  max-width: 400px;
  display: none;
  animation-name: loadingBgAnimation, translateFromLeft;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 1s;

}

#carouselIndicator {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

#activeCarouselIndicator {
  background-color: #0052FF;
  list-style: none;
  margin: 5px;
  width: 64px;
  height: 13px;
  border-radius: 8px
}

#inActiveCarouselIndicator {
  width: 14px;
  height: 13px;
  border-radius: 8px;
  background-color: white;
  list-style: none;
  border-radius: 8px;
  background-color: #0052FF;
  margin: 5px;
}

#inActiveCarouselIndicator4,
#inActiveCarouselIndicator5 {
  width: 14px;
  height: 13px;
  border-radius: 8px;
  background-color: white;
  list-style: none;
  border-radius: 8px;
  background-color: #0052FF;
  margin: 5px;
  display: block;

}

#inActiveCarouselIndicator3 {
  width: 14px;
  height: 13px;
  border-radius: 8px;
  background-color: white;
  list-style: none;
  border-radius: 8px;
  background-color: #0052FF;
  margin: 5px;
  display: block;
}

/****************** ppre ************************************/
#ppre1 {
  padding: 10px 6vw 10px 6vw;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#ppre2 {
  font-size: 25px;
  margin-right: 20px;
  border: 0px;
  background-color: transparent;
  padding: 0px;
}

#ppre3 {
  font-size: 20px;
  font-family: 'Space Grotesk', sans-serif;
  color: black;
  font-weight: 600;
}

#ppre4 {
  margin: 80px 6vw 10px 6vw;
}

#ppre5 {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

#ppre6 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

#ppre7 {
  font-size: 17px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

#ppre8 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

#ppre9 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

/********** media query for smaller screens ***********/

@media (max-width:1600px) {
  #carouselIndicator {
    display: flex;
  }
}

@media (min-width:1200px) {
  #inActiveCarouselIndicator3 {
    display: none;
  }
}

@media (min-width:1000px) {
  #hmre31 {
    padding-left: 10%;
  }
}

@media (max-width:1000px) {

  #cure2 {
    margin-bottom: 50px;
  }

  #pfre11 {
    height: 900px;
  }



  #pfre7 {
    text-align: start;
  }



  #ucre7 {
    margin-bottom: 40px;
  }

  #hmre29 {
    padding-left: 800px;
  }

  #homepage {
    margin-top: 130px;

  }

  #mainNavRoutesContainer {
    display: none;
  }

  #mainNavDropMenu {
    display: block;
  }

  #mainNavTogglerBtn {
    display: block;
  }

  #mainNavContactUsBtn {
    display: none;

  }

  #visionImgContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }

  #hmre12 {
    width: 90vw;
  }

}

@media (max-width:900px) {
  #ftre1 {
    display: none;
  }

  #ftre22 {
    display: block;
  }
}

@media (min-width:1400px) {
  #inActiveCarouselIndicator5 {
    display: none;
  }


}

@media (min-width:690px) {
  #inActiveCarouselIndicator4 {
    display: none;
  }


}

@media (max-width:700px) {
  #abre30 {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  #pfre22,
  #hmre21 {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  #pfre18,
  #pfre19,
  #pfre20,
  #pfre21,
  #pfre12 {
    background-color: #c0c2dd;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
  }

  #pfre18:hover,
  #pfre19:hover,
  #pfre20:hover,
  #pfre21:hover,
  #pfre12:hover {
    background-color: #b7b8d8;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
  }

  #pfre12 div,
  #pfre18 div,
  #pfre19 div,
  #pfre20 div,
  #pfre21 div {
    display: flex;
  }

  #ftre1 {
    padding: 20px 4vw 50px 4vw;
  }

  #msre3,
  .ge2,
  #pfre4 {
    font-size: 1.6rem;
  }

  .ge4 {
    display: none;
  }

  .ge5 {
    display: flex;
  }

  #care14,
  #ppre6,
  #ppre7,
  #ppre8 {
    font-size: .9rem;
  }

  #ftre11,
  #ftre12,
  #ftre7,
  #abre25,
  #pfre5,
  #ftre24 {
    font-size: .7rem;
  }


  #ucre5,
  #ucre12,
  .ge5,
  #ucre11,
  #msre4,
  #abre2,
  #care1,
  #care12,
  #abre10,
  #mainNavDropContactUsBtn,
  #sere1,
  #pfre7,
  #pfre8,
  #pfre31,
  #cure9,
  #cure8,
  #cure13,
  #ppre5 {
    font-size: .9rem;

  }

  #activeMainNavDropMenuLinks,
  #pfre44,
  #cure7,
  #cure14,
  #care11,
  #ftre5 {
    font-size: 1.1rem;
  }

  #pfre38,
  #care5 {
    font-size: 1.4rem;
  }

  #ftre6,
  #sere3,
  #ftre23,
  #pfre45,
  #pfre46 {
    font-size: .8rem;
  }



  #ftre3 {
    margin-right: 0px;
  }

  #pfre1 {
    margin-top: 100px;
    padding-top: 50px;
  }

  #pfre52 {
    font-size: 1.7rem;
  }
}


@media (max-width:500px) {
  #abre22 {
    height: 340px;
  }

  #msre9 {
    width: 100%;
  }

  #msre8 {
    width: 100%;
  }

  #hmre38 {
    width: 100px;
    height: 95px;
  }

  #hmre41 {
    font-size: 20px;
  }

  #hmre42 {
    font-size: 10px;
  }

  #msre7 {
    font-size: 12px;
  }

  #ge8 {
    padding: 4%;
  }

  #ge9 {
    font-size: 1.3rem;
  }

  #ge10 {
    font-size: .9rem;
  }

  #cure4 {
    margin-top: 20px;
  }

  #sdl6,
  #sdl5 {
    font-size: 12px;
  }

  #msre6::placeholder {
    font-size: 13px;
  }

  #boldOnboardingText {
    font-size: 1.5rem;
    min-height: 80px;
    line-height: 45px;
  }

  #onboardingContent,
  #hmre8 {
    font-size: .9rem;
  }

  #ucre1,
  #abre6 {
    background-size: 105px;
  }


  #hmre1 {
    padding-bottom: 30px;
  }

  #mainNavCompanyLogo {
    height: 40px;
  }

  #mainNavBar,
  #ge6 {
    height: 60px;
  }

  #mainNavDropMenu {
    top: 60px;
    animation-duration: .4s;

  }

  @keyframes mainNavDropMenu {
    from {
      top: 30px;
      left: -20%;
      opacity: 0;

    }

    to {
      top: 60px;
      left: 0;
      opacity: 1;
    }
  }

  #ftre4 {
    max-height: 30px;
  }

  #eachMainNavDropMenuLinks {
    font-size: 1rem;
  }



  #homepage {
    margin-top: 100px;
  }

  #ge1,
  #ge15 {
    margin: 100px 6vw 10px 6vw;

  }




  #pfre4 {
    line-height: 30px;
  }

  #trustees {
    width: 100vw;
    height: 120px;
  }

}


@media (max-width:352px) {
  #hmre38 {
    display: none;

  }

  #msre7 {
    font-size: 10px;
  }

  .trainedTechieProfileImg {
    height: 25px;
    width: 25px;
  }
}